define(['app', '$console', '$window'], (app, $console, $window) => {

  const subscribeAndSaveContracts = () => {
    const component = {};

    component.config = {
      selectors: {
        component: '[data-component=subscribeAndSaveContracts]',
        dropdown: '#subscribeAndSaveContracts_dropdown'

      },
      channels: {
        showSubscribeAndSaveContracts: 'subscribeAndSaveContracts/show',
        updateSubscribeAndSaveContracts: 'subscribeAndSaveContracts/update',
        subscribeAndSaveContractsChange: 'subscribeAndSave/contractsChange',
        changeContractIdSubscribeAndSaveContracts: 'subscribeAndSaveContracts/changeContractId',
        noContractsAvailable: 'subscribeAndSaveProductInBasket/noContracts'
      },
      attributes: {
        isVisible: 'data-visible',
        isBasketPage: 'data-basket-page',
        productId: 'data-product-id'
      }
    };

    component.init = (element, userDriven) => {
      component.element = element;
      component.dropdown = element.querySelector(component.config.selectors.dropdown);
      if(!userDriven) {
        component.subscribeToChannels();
      } else {
        app.publish(component.config.channels.subscribeAndSaveContractsChange)
      }
      component.bind();
    };

    component.bind = () => {
      component.dropdown.addEventListener('change', () => {
        component.changeDropdown(true);
      })
    };

    component.subscribeToChannels = () => {
      app.clear(component.config.channels.showSubscribeAndSaveContracts);
      app.clear(component.config.channels.updateSubscribeAndSaveContracts);
      app.subscribe(component.config.channels.showSubscribeAndSaveContracts,
        component.showSubscribeAndSaveContracts);
      app.subscribe(component.config.channels.updateSubscribeAndSaveContracts,
        component.updateSubscribeAndSaveContracts);
    };

    component.showSubscribeAndSaveContracts = (showContractsDropdown) => {
      component.element.setAttribute(component.config.attributes.isVisible, showContractsDropdown);
      if (showContractsDropdown) {
        component.changeDropdown(showContractsDropdown);
      } else {
        app.publish(component.config.channels.changeContractIdSubscribeAndSaveContracts, '', showContractsDropdown);
      }
    };

    component.updateSubscribeAndSaveContracts = (productId) => {
      let url = `/${productId}.subscribeAndSave?enableSubscribeAndSave=true`;

      app.ajax.get({
        url: url,
        success: component.successHandler,
        error: component.errorHandler
      });
    };

    component.errorHandler = () => {
      $console.error('ERROR: Could not retrieve new subscribeAndSaveContracts');
    };

    component.successHandler = (response) => {
      const parent = component.element.parentNode;
      parent.innerHTML = response;
      let element = parent.querySelector(component.config.selectors.component);

      component.init(element, true);
    };

    component.changeDropdown = (isSubsTab) => {
      let selectedContractId = component.dropdown.value;

      if (component.element.getAttribute(component.config.attributes.isBasketPage) === 'true') {
        let productID = component.element.parentElement.getAttribute(component.config.attributes.productId);
        $window.location = `/my.basket?page=basket&section=checkout&counter=1&buy=${productID}&subscriptionContractId=${selectedContractId}&enableSubscribeAndSave=true`;
      } else {
        app.publish(component.config.channels.noContractsAvailable, component.dropdown.disabled);
        app.publish(component.config.channels.changeContractIdSubscribeAndSaveContracts, selectedContractId, isSubsTab);
      }

    };

    return component;
  };

  return subscribeAndSaveContracts;
});
